/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import ScrollNav from "../components/ScrollNav"
require("normalize.css")
require("typeface-nunito-sans")

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nunito Sans'
  }
  p, ul, ol {
    font-size: 17px;
    line-height: 1.5
  }
  h2 {
    font-size: 47px;
  }
  h3 {
    font-size: 30px;
  }
  a {
    color: #c9a71e;
    text-decoration: none;
    font-weight: bold;
  }
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .CrossfadeImage {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
    padding-top: 56%;
  }
  .bg-image-fit {
    position: absolute !important;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .CrossfadeImage img {
    width: 100%;
  }
  @media (max-width: 767px) {
    h2 {
      font-size: 32px;
    }
  }
`

const Layout = ({ children, handleTrailer }) => {
  return (
    <>
      <GlobalStyle />
      <ScrollNav handleTrailer={handleTrailer} />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
