import React, { useEffect, useState } from "react"
import styled from "styled-components"
import logo from "../images/scroll-logo.svg"
import Img from "gatsby-image"
import { useStaticQuery, Link } from "gatsby"
import notify from "../images/notify.svg"
import trailer from "../images/trailer.svg"
import kickstarter from "../images/kickstarter-white.svg"
import Countdown from "react-countdown"

function ScrollNav({ handleTrailer }) {
  const data = useStaticQuery(graphql`
    query NavQuery {
      scrollBg: file(relativePath: { eq: "scroll-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const adjustMenu = e => {
    if (window.scrollY > 200 && !scrolled) {
      setScrolled(true)
    }
    if (window.scrollY <= 0) {
      setScrolled(false)
    }
  }

  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", adjustMenu)
    return () => {
      window.removeEventListener("scroll", adjustMenu)
    }
  }, [scrolled])

  const Nav = styled.nav`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    transition: top 0.3s;

    .kickstarter-container p {
      margin: 0;
      font-size: 22px;
      color: #a4d033;
      font-weight: bold;
      img {
        max-width: 240px;
        width: 100%;
      }
    }

    > .nav-info-container {
      display: flex;
      > div:nth-of-type(even) {
        margin: 0 25px;
      }
      > .cta-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      > .cta-container > div {
        width: 200px;
      }
      > .cta-container button {
        background: none;
        border: none;
        width: 100%;
        outline: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cta-container > div:nth-of-type(1) .cta-content {
        position: absolute;
        width: 100%;
        color: white;
        font-size: 20px;
        top: 10px;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .cta-container > div:nth-of-type(1) .cta-content span {
        color: #aed843;
        font-size: 1vw;
      }
      .cta-container > div:nth-of-type(2) .cta-content {
        position: absolute;
        width: 100%;
        color: #2d373a;
        font-size: 20px;
        top: 10px;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > .cta-container img {
        width: 100%;
      }
      @media (max-width: 1260px) {
        > div:nth-of-type(1),
        > div:nth-of-type(2) {
          display: none;
        }
      }
      @media (max-width: 665px) {
        display: none;
      }
    }
    @media (max-width: 665px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  `

  const CountdownComp = styled.div`
    display: flex;
    margin-bottom: -22px;
    position: relative;
    bottom: -5px;
    > span:nth-of-type(even) {
      margin: 0 12px;
    }
    > span {
      font-size: 42px;
      font-weight: 900;
      color: #feffff;
      line-height: 1;
      text-align: center;
      background: #a4d033;
      width: 90px;
      height: 90px;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > span {
        font-size: 22px;
        color: #2d373a;
        font-weight: 300;
      }
    }
  `

  const Renderer = ({ days, hours, minutes }) => {
    return (
      <CountdownComp>
        <span>
          {days}
          <br />
          <span>days</span>
        </span>
        <span>
          {hours}
          <br />
          <span>hours</span>
        </span>
        <span>
          {minutes}
          <br />
          <span>min</span>
        </span>
      </CountdownComp>
    )
  }

  const { scrollBg } = data
  return (
    <div
      style={{
        zIndex: 99,
        transition: "all .3s",
        position: "fixed",
        width: "100%",
        transform: scrolled ? "translate(0, 0px)" : "translate(0, -200px)",
      }}
    >
      <Nav>
        <Img className="bg-image-fit" fluid={scrollBg.childImageSharp.fluid} />
        <Link to={"/"}>
          <img src={logo} />
        </Link>
        <div className="nav-info-container">
          {/* <Countdown date={new Date("Apr 01 2021")} renderer={Renderer} /> */}
          <div className="kickstarter-container">
            <p>
              until
              <br />
              <img src={kickstarter} />
            </p>
          </div>
          <div className="cta-container">
            <div>
              <button>
                <img src={notify} />
                <span class="cta-content">
                  <a
                    id="notify-button"
                    style={{ color: "white", fontSize: 16 }}
                    href="#notify"
                  >
                    KEEP ME UPDATED!
                  </a>
                </span>
              </button>
            </div>
            <div>
              <button>
                <a id="trailer-button" onClick={handleTrailer}>
                  <img src={trailer} />
                  <span class="cta-content">VIEW TRAILER</span>
                </a>
              </button>
            </div>
          </div>
        </div>
      </Nav>
    </div>
  )
}

export default ScrollNav
